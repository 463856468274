import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "..";
import { gamesConstants, tenantsConstants } from "../constants";
import { loadedGames } from "../actions/games";

import { buildApiUrl } from "../../utils/api";
import { accessPages, operationsAccess } from "../../utils/users";

const gamesApiUrl = buildApiUrl("/admin/bonuses/selects/games");
const gamesApiUrlPlatform = buildApiUrl("/admin/bonuses/selects/games-platform");

let loadingForTenant = null;
let ts = null;

function* loadGamesSaga(action) {
  const opAccess = operationsAccess(accessPages.BONUSES_SELECTS);

  const { tenants, games } = store.getState();
  let currentTenant = tenants.currentTenant || '2';
  if (action.tenantId) {
    currentTenant = action.tenantId;
  }

  currentTenant = currentTenant.toString();

  if (currentTenant === games.tenant_id) return;

  const now = new Date().getTime();

  if (currentTenant && loadingForTenant === currentTenant) {
    if (now - ts < 500) return;
  }

  // console.log("[SAGA] loadGamesSaga", currentTenant, games.tenant_id, now, now - ts);

  loadingForTenant = currentTenant;
  ts = now;

  try {
    if (!opAccess.canList) return;

    const { data } = yield axios.get(`${gamesApiUrlPlatform}?tenant_id=${currentTenant}`);
    yield put(loadedGames(data, currentTenant));
  } catch (e) {
    console.log("[SAGA] loadGamesSaga error", e.toString());
  }
}

export default function* watchGamesSaga() {
  //console.log("[SAGA] watching games");

  yield takeEvery(gamesConstants.LOAD, loadGamesSaga);
  yield takeEvery(tenantsConstants.SET_CURRENT_TENANT, loadGamesSaga);
}
