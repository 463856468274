import React from 'react';
import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./LazyLoadingErrorBoundary.scss";

/*** Adapted from https://reactjs.org/docs/error-boundaries.html ***/
export default class LazyLoadingErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      console.error(this.state.error);
      let infoText = 'Something went wrong! Please reload the page.';
      const stringError = this.state.error.toString();
      if (stringError.includes('Failed to fetch dynamically imported module')) {
        infoText = "Page rendering failed! We've probably updated Celestial and you need to reload the website.";
      }

      return (
        <div className='lazy-loading-error-boundary'>
          <div>{infoText}</div>
          <Button onClick={() => window.location.reload()} color="primary">
            Click to Reload
          </Button>
          {this.state.error?.stack != null && <Accordion defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>Error Details</div>
            </AccordionSummary>
            <AccordionDetails>
              <pre>{this.state.error?.stack}</pre>
            </AccordionDetails>
          </Accordion>}
        </div>
      );
    }

    return this.props.children;
  }
}
