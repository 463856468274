import { produce } from "immer";
import { bulkDeductionGrant } from "../constants";
import { chunk } from "lodash";

const IDLE_STATUS = 0;
const IN_PROGRESS_STATUS = 1;
const DONE_STATUS = 2;

const initialState = {
  status: IDLE_STATUS,
  failed: 0,
  succeeded: 0,
  players: {},
  total: 0,
  elapsedTime: 0,
  requests: [],
  currentRequest: 0,
  chunks: [],
  currentChunk: 0,
};

const bulkDeductionGrantReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case bulkDeductionGrant.START: {
        if (action.data && action.data.requests) {
          const { requests } = action.data;
          draft.status = IN_PROGRESS_STATUS;
          draft.requests = requests;
          draft.total = requests.length;

          const batchSize = window.ini?.REACT_APP_BULK_TOOL_BATCH_SIZE ?? bulkDeductionGrant.MAX_CONCURRENT_REQUESTS;
          draft.chunks = chunk(draft.requests, batchSize);
          draft.currentChunk = 0;
          draft.elapsedTime = 0;
          draft.estimatedRemainingTime = 0
        }
        break;
      }
      case bulkDeductionGrant.DONE: {
        if (draft.currentChunk >= draft.chunks.length) {
          console.warn("Reducer: DONE action, no more chunks to process");
          draft.status = DONE_STATUS;
          draft.estimatedRemainingTime = 0;
          break;
        }

        if (action.data && action.data.results) {
          const { results, elapsedTime, currentChunk  } = action.data;

          console.warn("Reducer: DONE action", { elapsedTime, currentChunk });
          console.log("Reducer: DONE action", {results});
          draft.elapsedTime += elapsedTime;
          const currentChunkLength = draft.chunks[currentChunk].length;
          const averageTimePerRequest = elapsedTime / currentChunkLength;
          const remainingRequests = draft.chunks.slice(currentChunk + 1).reduce((sum, chunk) => sum + chunk.length, 0);
          draft.estimatedRemainingTime = Math.ceil(remainingRequests * averageTimePerRequest);
          console.log(`Estimated remaining time after chunk ${currentChunk + 1}: ${draft.estimatedRemainingTime} ms`);

          for (const result of results) {
            const request = result.requestData;
            let originalRequestIndex = request.indexInChunk;
            let originalRequest = draft.requests[originalRequestIndex];
            let isSameRequest = originalRequest != null;
            if (isSameRequest) {
              for (const [key, value] of Object.entries(request)) {
                if (originalRequest[key] !== value) {
                  isSameRequest = false;
                  break;
                }
              }
            }
            if (!isSameRequest) {
              originalRequestIndex = draft.requests.findIndex(r => {
                for (const [key, value] of Object.entries(r)) {
                  if (request[key] == null || value !== request[key]) {
                    return false;
                  }
                }
                return true;
              });
            }
            if (originalRequestIndex > -1 && result.response) {
              draft.requests[originalRequestIndex].response = result.response;
            }
            const playerId = request.playerId;
            if (result.success) {
              draft.players[playerId] = "Success";
              draft.succeeded += 1;
            } else {
              draft.players[playerId] = result?.message || "Unknown Error";
              draft.failed += 1;
            }
          }
          draft.currentChunk = currentChunk + 1;
        } else {
          console.error("Reducer: Missing or invalid results in DONE action");
        }
        break;
      }
      case bulkDeductionGrant.CLEAR: {
        draft.status = IDLE_STATUS;
        draft.failed = 0;
        draft.succeeded = 0;
        draft.players = {};
        draft.total = 0;
        draft.elapsedTime = 0;
        draft.estimatedRemainingTime = null;
        draft.requests = [];
        draft.chunks = [];
        draft.currentChunk = 0;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default bulkDeductionGrantReducer;