// ** React Imports
import { Suspense, useContext, lazy } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LinearProgress from "@mui/material/LinearProgress";

// ** Utils
import { isUserLoggedIn } from '@utils'
import { useLayout } from '@hooks/useLayout'
import { AbilityContext } from '@src/utility/context/Can'
import { useRouterTransition } from '@hooks/useRouterTransition'

// ** Custom Components
// import Spinner from '@components/spinner/Loading-spinner' // Uncomment if your require content fallback
import LayoutWrapper from '../@core-overwrite/layouts/components/layout-wrapper'

// ** Router Components
import { BrowserRouter as AppRouter, Route, Switch, Redirect } from 'react-router-dom'

import { ReactRouterGlobalHistory } from "../../history";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";


// ** Routes & Default Routes
import { DefaultRoute, Routes } from './routes'

// ** Layouts
import BlankLayout from '@layouts/BlankLayout';
import DetailsLayout from '@src/layouts/DetailsLayout';
import CustomerLayout from '@src/layouts/CustomerLayout';
import VerticalLayout from '@src/layouts/VerticalLayout';
import HorizontalLayout from '@src/layouts/HorizontalLayout';

import Login from "../views/Login"
import ExtraHeader from '../layouts/components/extra';

import LazyLoadingErrorBoundary from './LazyLoadingErrorBoundary'

const Router = () => {
  // ** Hooks
  const [layout, setLayout] = useLayout()
  const [transition, setTransition] = useRouterTransition()
  const auth = useSelector(state => state.auth)

  // ** ACL Ability Context
  const ability = useContext(AbilityContext)

  // ** Default Layout
  const DefaultLayout = layout === 'horizontal' ? 'HorizontalLayout' : 'VerticalLayout'

  // ** All of the available layouts
  const Layouts = { BlankLayout, DetailsLayout, CustomerLayout, VerticalLayout, HorizontalLayout }

  // ** Current Active Item
  const currentActiveItem = null

  // ** Return Filtered Array of Routes & Paths
  const LayoutRoutesAndPaths = layout => {
    const LayoutRoutes = []
    const LayoutPaths = []

    if (Routes) {
      Routes.filter(route => {
        // ** Checks if Route layout or Default layout matches current layout
        if (route.layout === layout || (route.layout === undefined && DefaultLayout === layout)) {
          LayoutRoutes.push(route)
          LayoutPaths.push(route.path)
        }
      })
    }

    return { LayoutRoutes, LayoutPaths }
  }

  const NotAuthorized = lazy(() => import('@src/views/NotAuthorized'))

  // ** Init Error Component
  const Error = lazy(() => import('@src/views/Error'))

  /**
   ** Final Route Component Checks for Login & User Role and then redirects to the route
   */
  const FinalRoute = props => {
    const route = props.route;
    let action, resource;

    // ** Assign vars based on route meta
    if (route.meta) {
      action = route.meta.action ? route.meta.action : null
      resource = route.meta.resource ? route.meta.resource : null
    }

    if (
      (!isUserLoggedIn() && route.meta === undefined) ||
      (!isUserLoggedIn() && route.meta && !route.meta.authRoute && !route.meta.publicRoute)
    ) {
      /**
       ** If user is not Logged in & route meta is undefined
       ** OR
       ** If user is not Logged in & route.meta.authRoute, !route.meta.publicRoute are undefined
       ** Then redirect user to login
       */

      return <Login {...props} />
    } else if (route.meta && route.meta.authRoute && isUserLoggedIn()) {
      // ** If route has meta and authRole and user is Logged in then redirect user to home page (DefaultRoute)
      return <Redirect to={`${window.ini.REACT_APP_ROOT_PATH}/home`} />
      //  } else if (isUserLoggedIn() && !ability.can(action || 'read', resource)) {
      // ** If user is Logged in and doesn't have ability to visit the page redirect the user to Not Authorized
      //    return <Redirect to='/misc/not-authorized' />
    }
    return <route.component key={props.location.pathname} {...props} />
  }

  // ** Return Route to Render
  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      // ** Convert Layout parameter to Layout Component
      // ? Note: make sure to keep layout and component name equal

      const LayoutTag = Layouts[layout]

      // ** Get Routes and Paths of the Layout
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout)

      // ** We have freedom to display different layout for different route
      // ** We have made LayoutTag dynamic based on layout, we can also replace it with the only layout component,
      // ** that we want to implement like VerticalLayout or HorizontalLayout
      // ** We segregated all the routes based on the layouts and Resolved all those routes inside layouts

      // ** RouterProps to pass them to Layouts
      const routerProps = {}

      return (
        <Route path={LayoutPaths} key={index}>
          <LayoutTag
            routerProps={routerProps}
            layout={layout}
            setLayout={setLayout}
            transition={transition}
            setTransition={setTransition}
            currentActiveItem={currentActiveItem}
          >
            <Switch>
              {LayoutRoutes.map(route => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact === true}
                    render={props => {
                      // ** Assign props to routerProps
                      Object.assign(routerProps, {
                        ...props,
                        meta: route.meta
                      })

                      return (
                        <LazyLoadingErrorBoundary>
                          <Suspense fallback={<div className="suspense-loader"><LinearProgress /></div>}>
                            {/* Layout Wrapper to add classes based on route's layout, appLayout and className */}
                            <LayoutWrapper
                              layout={DefaultLayout}
                              transition={transition}
                              setTransition={setTransition}
                              /*eslint-disable */
                              {...(route.appLayout
                                ? {
                                  appLayout: route.appLayout
                                }
                                : {})}
                              {...(route.meta
                                ? {
                                  routeMeta: route.meta
                                }
                                : {})}
                              {...(route.className
                                ? {
                                  wrapperClass: route.className
                                }
                                : {})}
                            /*eslint-enable */
                            >
                              <ExtraHeader route={route} />

                              <FinalRoute route={route} {...props} />
                            </LayoutWrapper>
                          </Suspense>
                        </LazyLoadingErrorBoundary>
                      )
                    }}
                  />
                )
              })}
            </Switch>
          </LayoutTag>
        </Route>
      )
    })
  }

  let content = null;

  if (!auth.authed) {
    content = <Layouts.BlankLayout>
      <Login />
    </Layouts.BlankLayout>;
  } else {
    content = <Switch>
      {/* If user is logged in Redirect user to DefaultRoute else to login */}
      {/* <Route
        exact
        path='/'
        render={() => {
          return isUserLoggedIn() ? <Redirect to={DefaultRoute} /> : <Redirect to='/login' />
        }}
      /> */}
      <Route
        exact
        path='/'
        render={() => {
          return <Redirect to={DefaultRoute} />
        }}
      />
      {/* Not Auth Route */}
      <Route
        exact
        path='/not-authorized'
        render={props => (
          <Layouts.BlankLayout>
            <NotAuthorized />
          </Layouts.BlankLayout>
        )}
      />
      {ResolveRoutes()}
      {/* NotFound Error page */}
      <Route path='*' component={Error} />/
    </Switch>;
  }

  return (
    <AppRouter>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <ReactRouterGlobalHistory />
        {content}
      </QueryParamProvider>
    </AppRouter>
  )
}

export default Router
