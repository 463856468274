import { countryFlagsConstants } from "../constants";

export const loadCountryFlags = (data) => ({
  type: countryFlagsConstants.LOAD,
  data
});

export const loadedCountryFlags = (data) => ({
  type: countryFlagsConstants.LOADED,
  data: data
});