import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";
import { endpoints } from "src/api/endpoints";

import store from "../../store";
import { sitesConstants } from "../constants";
import { sitesLoadAll, sitesLoad, sitesIsLoading, sitesAddSite, siteRemoveLocal } from "../actions/sites";

function* fetchPageSitesSaga(action) {
  const { sites } = store.getState();

  yield put(sitesIsLoading(true));
  try {
    if (action.data.pageUrl) {
      const siteData = sites.pages[action.data.pageUrl];

      const params = {};
      if (siteData.reg_key) {
        params.key = siteData.reg_key;
      }

      if (!siteData.loaded && siteData.apiUrl) {
        const { data } = yield axios.get(siteData.apiUrl, { params });
        yield put(
          sitesLoad({
            pageUrl: action.data.pageUrl,
            sites: data,
          })
        );
      }
    }
  } catch (e) {
    console.log(e.toString());
  }
  yield put(sitesIsLoading(false));
}

function* copySiteConfigSaga(action) {
  const { sites } = store.getState();

  yield put(sitesIsLoading(true));
  try {
    if (action.data.pageUrl) {
      const siteData = sites.pages[action.data.pageUrl];

      if (siteData.copyUrl) {
        const params = { ...action.data.params };
        if (siteData.reg_key) {
          params.key = siteData.reg_key;
        }
        yield axios.get(siteData.copyUrl, { params });
        yield put(
          sitesAddSite({
            pageUrl: action.data.pageUrl,
            site: action.data.site,
          })
        );
      }
    }
  } catch (e) {
    console.log(e.toString());
  }
  yield put(sitesIsLoading(false));
}

function* deleteSiteRemoteSaga(action) {
  const { sites } = store.getState();

  yield put(sitesIsLoading(true));
  try {
    if (action.data.pageUrl) {
      const siteData = sites.pages[action.data.pageUrl];

      if (siteData.deleteUrl) {
        const params = { ...action.data.params };
        if (siteData.reg_key) {
          params.key = siteData.reg_key;
        }
        yield axios.get(siteData.deleteUrl, { params });
        yield put(
          siteRemoveLocal({
            pageUrl: action.data.pageUrl,
            site: action.data.site,
          })
        );
      }
    }
  } catch (e) {
    console.log(e.toString());
  }
  yield put(sitesIsLoading(false));
}

//
function* fetchAllSitesSaga() {
  const postData = {
    exactFields: [],
    exactValues: [],
    page: 1,
    rowsPerPage: 10000,
  };

  try {
    const { data } = yield axios.post(endpoints.site.list, postData);

    yield put(sitesLoadAll(data.data.rows));
  } catch (e) {
    console.error("failed to load sites");
  }
}

export default function* watchSitesSaga() {
  //console.log("[SAGA] watching sites");
  yield takeEvery(sitesConstants.FETCH_ALL, fetchAllSitesSaga);
  // yield takeEvery(sitesConstants.FETCH, fetchPageSitesSaga);
  // yield takeEvery(sitesConstants.COPY_TENANT_CONFIG, copySiteConfigSaga);
  // yield takeEvery(sitesConstants.DELETE_TENANT_REMOTE, deleteSiteRemoteSaga);
}
