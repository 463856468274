import { gamesConstants } from "../constants";

export const loadGames = (data) => ({
  type: gamesConstants.LOAD,
  data
});

export const loadedGames = (data, tenantId) => ({
  type: gamesConstants.LOADED,
  data: data,
  tenantId,
});