import { produce } from "immer";
import { countryFlagsConstants } from "../constants";

const initialState = {
  images: [],
};

const countryFlagsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case countryFlagsConstants.LOADED: {
        // console.log("countryFlagsReducer", action);
        draft.images = action.data;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default countryFlagsReducer;