import { takeEvery, put } from "redux-saga/effects";

import { countryFlagsConstants } from "../constants";
import {loadedCountryFlags} from "../actions/country-flags";
import {getCountries} from "react-phone-number-input/input";

const flagImgUrl = (countryCode) => {
  return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${countryCode.toLowerCase()}.svg`;
}

const fallbackImgUrl = (countryCode) => {
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode.toUpperCase()}.svg`;
};

function* loadCountryFlagsSaga(action) {
  try {
    const countryCodes = getCountries();

    const promises = countryCodes.map(async (countryCode) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = flagImgUrl(countryCode);
        img.onload = () => resolve({countryCode, img});
        img.onerror = () => {
          const fallbackImg = new Image();
          fallbackImg.src = fallbackImgUrl(countryCode);
          fallbackImg.onload = () => resolve({countryCode, img: fallbackImg});
          fallbackImg.onerror = () => reject({countryCode});
        }
      });
    });

    const images = yield Promise.all(promises);

    yield put(loadedCountryFlags(images));
  } catch (e) {
    console.log("[SAGA] loadCountryFlagsSaga error", e.toString());
  }
}

export default function* watchCountryFlagsSaga() {
  // console.log("[SAGA] watching CountryFlags");
  yield takeEvery(countryFlagsConstants.LOAD, loadCountryFlagsSaga);
}
