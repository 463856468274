import { produce } from "immer";
import { bulkTagsGrant } from "../constants";
import { chunk } from "lodash";

const IDLE_STATUS = 0;
const IN_PROGRESS_STATUS = 1;
const DONE_STATUS = 2;

const initialState = {
  status: IDLE_STATUS,
  failed: 0,
  succeeded: 0,
  players: {},
  total: 0,
  elapsedTime: 0,
  estimatedRemainingTime: null,
  requests: [],
  chunks: [],
  currentChunk: 0,
};

const bulkTagsGrantReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case bulkTagsGrant.START: {
        if (action.data && action.data.requests) {
          const { requests } = action.data;
          draft.status = IN_PROGRESS_STATUS;
          draft.requests = requests;
          draft.total = requests.reduce((sum, request) => sum + request.playerId.length, 0);

          const batchSize = bulkTagsGrant.MAX_CONCURRENT_REQUESTS;    // each request is already a batch
          draft.chunks = chunk(draft.requests, batchSize);
          draft.currentChunk = 0;
          draft.elapsedTime = 0;
          draft.estimatedRemainingTime = 0;
        }
        break;
      }
      case bulkTagsGrant.DONE: {
        if (draft.currentChunk >= draft.chunks.length) {
          console.warn("Reducer: DONE action, no more chunks to process");
          draft.status = DONE_STATUS;
          draft.estimatedRemainingTime = 0; // No remaining time
          break;
        }

        if (action.data && action.data.results) {
          const { results, elapsedTime, currentChunk  } = action.data;

          console.warn("Reducer: DONE action", { elapsedTime, currentChunk });
          draft.elapsedTime += elapsedTime;
          const currentChunkLength = draft.chunks[currentChunk].length;
          const averageTimePerRequest = elapsedTime / currentChunkLength;
          const remainingRequests = draft.chunks.slice(currentChunk + 1).reduce((sum, chunk) => sum + chunk.length, 0);
          draft.estimatedRemainingTime = Math.ceil(remainingRequests * averageTimePerRequest);
          console.log(`Estimated remaining time after chunk ${currentChunk + 1}: ${draft.estimatedRemainingTime} ms`);

          for (const result of results) {
            const request = result.requestData;
            if (result.success) {
              request?.playerId?.forEach(playerId => {
                draft.players[playerId] = "Success";
                draft.succeeded += 1;
              });
            } else {
              request?.playerId?.forEach(playerId => {
                draft.players[playerId] = result?.message || "Unknown Error";
                draft.failed += 1;
              });
            }
          }
          draft.currentChunk = currentChunk + 1;
        } else {
          console.error("Reducer: Missing or invalid results in DONE action");
        }
        break;
      }
      case bulkTagsGrant.CLEAR: {
        draft.status = IDLE_STATUS;
        draft.failed = 0;
        draft.succeeded = 0;
        draft.players = {};
        draft.total = 0;
        draft.elapsedTime = 0;
        draft.estimatedRemainingTime = null;
        draft.requests = [];
        draft.chunks = [];
        draft.currentChunk = 0;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default bulkTagsGrantReducer;